import React from 'react';
import { connect } from 'react-redux';
import { Route, Router, Switch, withRouter } from 'react-router-dom';
import { LinkContainer } from 'react-router-bootstrap';
import PropTypes from 'prop-types';
import { purgeStoredState } from 'redux-persist';
import Bugsnag from '@bugsnag/js';
import { persistConfig } from '../../Redux';
import AddUser from '../Administration/Users/AddUser';
import Dashboard from '../Dashboard/Dashboard';
import Login from '../Login/Login';
import LoginActions from '../../Redux/Login.redux';
import MyProfile from '../AccountManagement/MyProfile/MyProfile';
import NewPassword from '../NewPassword/NewPassword';
import NotFound from '../NotFound/NotFound';
import OfflineDonations from '../OfflineDonations/OfflineDonations';
import OfflineDonationsDashboard from '../OfflineDonations/OfflineDonationsDashboard/OfflineDonationsDashboard';
import Users from '../Administration/Users/Users';
import Settings from '../Administration/Settings/Settings';
import VirtualTerminal from '../VirtualTerminal/VirtualTerminal';
import AddOrganization from '../Administration/Organizations/AddOrganization';
import ResetPassword from '../ResetPassword/ResetPassword';
import Sidenav from '../Sidenav/Sidenav';
import WithAuthorization from '../WithAuthorization/WithAuthorization';
import api from '../../Services/Api';
import constants from '../../Helpers/constants';
import history from '../../Router/history';
import './LoginCheck.scss';
import ProgramDesignations from '../ProgramDesignations/ProgramDesignations';
import Location from '../VirtualTerminal/Location';
import CampaignLocation from '../VirtualTerminal/CampaignLocation';
import UserLocation from '../VirtualTerminal/UserLocation';
import EditLocation from '../VirtualTerminal/EditLocation';
import EditCampaignLocation from '../VirtualTerminal/EditCampaignLocation';
import EditUserLocation from '../VirtualTerminal/EditUserLocation';
import CreateReports from '../ScheduledReports/CreateReports';
import Reports from '../ScheduledReports/Reports';
import RecurringDonationsMigration from '../RecurringDonationMigration/RecurringDonationMigration';
import Designations from '../RLC/Designations';
import EditDesignation from '../RLC/EditDesignation';
import AccountHome from '../RLC/AccountHome';
import Campaigns from '../RLC/Campaigns';
import Organizations from '../Administration/Organizations/Organizations';
import RlcLogs from '../RLC/Logs';
import Queues from '../Administration/Queues/Queues';
import Caches from '../Administration/Caches/Caches';

const Version = () => {
  return <div>VERSION: {process.env.REACT_APP_VERSION || 'unknown'}</div>;
};

const ErrorTest = () => {
  throw new Error('Testing BugSnag Integration');
};

class LoginCheck extends React.Component {
  state = {
    loading: true,
  };

  constructor(props) {
    super(props);
    this.purgeStore = this.purgeStore.bind(this);
    this.verifyUserIsLoggedIn = this.verifyUserIsLoggedIn.bind(this);
    this.isLoggedIn = this.isLoggedIn.bind(this);
    this.fetchUserSession = this.fetchUserSession.bind(this);

    this.historyUnListen = null;
  }

  componentWillUnmount() {
    this.historyUnListen?.();
  }

  async fetchUserSession() {
    try {
      const result = await api.getUserSession();
      const session = result.data;
      await this.props.loginSuccess(session.features, session.user, session.role);
      this.setState({ loading: false });
    } catch {
      this.setState({ loading: false });
    }
  }

  async verifyUserIsLoggedIn() {
    if (!this.isLoggedIn()) {
      api.removeUserInfo();
    }
  }

  isLoggedIn() {
    const { featurePermissions, user } = this.props;
    return featurePermissions && !!user?.id;
  }

  componentDidMount() {
    this.fetchUserSession();

    this.historyUnListen = history.listen(() => {
      this.verifyUserIsLoggedIn();
    });
  }

  componentDidUpdate(prevProps) {
    if (!prevProps.user.id && this.props.user.id) {
      this.registerUser(this.props.user);
    }
  }

  /**
   * @param {User} user
   */
  registerUser(user) {
    Bugsnag.setUser(user.id, user.email || null, `${user.firstName || ''} ${user.lastName || ''}`.trim());
  }

  purgeStore() {
    purgeStoredState(persistConfig);
  }

  render() {
    const { featurePermissions, user, selectedOrganizationId } = this.props;

    if (this.state.loading) {
      return null;
    }

    const isLoggedIn = this.isLoggedIn();

    return (
      <Router history={history}>
        <div className="app">
          {isLoggedIn && !user.isOneTimePassword && (
            <div>
              <div
                className={
                  process.env.REACT_APP_NODE_ENV !== constants.ENVIRONMENTS.PRODUCTION
                    ? 'top-nav test-env-highlight'
                    : 'top-nav'
                }
              >
                <LinkContainer to={constants.ROUTES.MY_PROFILE} className="avatar__container">
                  <div>
                    <i className="fa fa-user avatar"></i>
                    <span className="avatar__name">{user.firstName}</span>
                  </div>
                </LinkContainer>
              </div>
              <Sidenav />
              <div className="routeContainer">
                <Switch>
                  <Route exact path="/" component={Dashboard} />
                  <Route exact path="/version" component={Version} />
                  <Route
                    exact
                    path="/error-test"
                    component={() => (
                      <WithAuthorization Component={withRouter(ErrorTest)} feature={constants.FEATURES.ADMIN} />
                    )}
                  />
                  <Route path={constants.ROUTES.MY_PROFILE} component={MyProfile} />
                  <Route
                    path={`${constants.ROUTES.ADMIN_ORGANIZATIONS}/:organizationId?/(edit|create)`}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(AddOrganization)}
                        feature={constants.FEATURES.ADMIN}
                        role={constants.ROLES.ADMIN}
                      />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.ADMIN_ORGANIZATIONS}
                    component={() => (
                      <WithAuthorization
                        Component={Organizations}
                        feature={constants.FEATURES.ADMIN}
                        role={constants.ROLES.ADMIN}
                      />
                    )}
                  />
                  <Route
                    path={`${constants.ROUTES.ADMIN_USERS}/:userId?/(edit|create)`}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(AddUser)}
                        feature={constants.FEATURES.ADMIN}
                        role={constants.ROLES.ADMIN}
                      />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.ADMIN_USERS}
                    component={() => (
                      <WithAuthorization
                        Component={Users}
                        feature={constants.FEATURES.ADMIN}
                        role={constants.ROLES.ADMIN}
                      />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.ADMIN_SETTINGS}
                    component={() => (
                      <WithAuthorization
                        Component={Settings}
                        feature={constants.FEATURES.ADMIN}
                        path={constants.ROUTES.ADMIN_SETTINGS}
                        role={constants.ROLES.ADMIN}
                      />
                    )}
                  />

                  <Route
                    path={constants.ROUTES.RECURRING_DONATIONS_MIGRATE}
                    component={() => (
                      <WithAuthorization
                        Component={RecurringDonationsMigration}
                        feature={constants.FEATURES.RECURRING_DONATIONS_MIGRATION}
                      />
                    )}
                  />

                  <Route
                    exact
                    path={constants.ROUTES.OFFLINE_DONATIONS}
                    component={() => (
                      <WithAuthorization Component={OfflineDonations} feature={constants.FEATURES.OFFLINE_DONATIONS} />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.OFFLINE_DONATIONS_DASHBOARD}
                    component={() => (
                      <WithAuthorization
                        Component={OfflineDonationsDashboard}
                        feature={constants.FEATURES.OFFLINE_DONATIONS_DASHBOARD}
                      />
                    )}
                  />
                  <Route
                    path={`${constants.ROUTES.SCHEDULED_REPORTS}/:reportId?/(edit|create)`}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(CreateReports)}
                        feature={constants.FEATURES.SCHEDULED_REPORTS}
                        role={constants.ROLES.SCHEDULED_REPORTS}
                      />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.SCHEDULED_REPORTS}
                    component={() => (
                      <WithAuthorization Component={Reports} feature={constants.FEATURES.SCHEDULED_REPORTS} />
                    )}
                  />
                  <Route
                    exact
                    path={constants.ROUTES.PROGRAM_DESIGNATIONS}
                    component={() => (
                      <WithAuthorization
                        Component={ProgramDesignations}
                        feature={constants.FEATURES.PROGRAM_DESIGNATIONS}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={constants.ROUTES.VIRTUAL_TERMINAL}
                    component={() => (
                      <WithAuthorization
                        Component={VirtualTerminal}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL}
                        isProgramDesignationEnabled={Boolean(
                          featurePermissions[selectedOrganizationId]?.VTProgramDesignations,
                        )}
                        isVTSalesforceMetadataEnabled={Boolean(
                          featurePermissions[selectedOrganizationId]?.VTSalesforceMetadata,
                        )}
                        isVTCustomQuestionsEnabled={Boolean(
                          featurePermissions[selectedOrganizationId]?.VTCustomQuestions,
                        )}
                        isVTSupporterSearchEnabled={Boolean(
                          featurePermissions[selectedOrganizationId]?.VTSupporterSearch,
                        )}
                        isVTAutoGenerateEmailEnabled={Boolean(
                          featurePermissions[selectedOrganizationId]?.VTAutoGenerateEmail,
                        )}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={constants.ROUTES.VIRTUAL_TERMINAL_LOCATION}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(Location)}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${constants.ROUTES.VIRTUAL_TERMINAL_LOCATION}/:id?/(edit|create)`}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(EditLocation)}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={constants.ROUTES.VIRTUAL_TERMINAL_CAMPAIGN_LOCATION}
                    component={() => (
                      <WithAuthorization
                        Component={CampaignLocation}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${constants.ROUTES.VIRTUAL_TERMINAL_CAMPAIGN_LOCATION}/:id?/(edit|create)`}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(EditCampaignLocation)}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={constants.ROUTES.VIRTUAL_TERMINAL_USER_LOCATION}
                    component={() => (
                      <WithAuthorization
                        Component={UserLocation}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                      />
                    )}
                  />
                  <Route
                    exact
                    path={`${constants.ROUTES.VIRTUAL_TERMINAL_USER_LOCATION}/:id?/(edit|create)`}
                    component={() => (
                      <WithAuthorization
                        Component={withRouter(EditUserLocation)}
                        feature={constants.FEATURES.VIRTUAL_TERMINAL_LOCATION}
                      />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.RLC_DESIGNATIONS}
                    component={() => (
                      <WithAuthorization Component={Designations} feature={constants.FEATURES.RLC_MAPPING} />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.RLC_DESIGNATIONS_CREATE}
                    component={() => (
                      <WithAuthorization Component={EditDesignation} feature={constants.FEATURES.RLC_MAPPING} />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.RLC_ACCOUNT_HOME}
                    exact
                    component={() => (
                      <WithAuthorization Component={AccountHome} feature={constants.FEATURES.RLC_MAPPING} />
                    )}
                  />
                  <Route
                    path={`${constants.ROUTES.RLC_ACCOUNT_HOME}/:id${constants.ROUTES.RLC_CAMPAIGNS}`}
                    component={() => (
                      <WithAuthorization Component={withRouter(Campaigns)} feature={constants.FEATURES.RLC_MAPPING} />
                    )}
                  />
                  <Route
                    path={constants.ROUTES.RLC_LOGS}
                    component={() => <WithAuthorization Component={RlcLogs} feature={constants.FEATURES.ADMIN} />}
                  />
                  <Route
                    path={`${constants.ROUTES.QUEUES}/:id?/:status?`}
                    component={() => (
                      <WithAuthorization Component={withRouter(Queues)} feature={constants.FEATURES.ADMIN} />
                    )}
                  />
                  <Route
                    path={`${constants.ROUTES.CACHES}`}
                    component={() => (
                      <WithAuthorization Component={withRouter(Caches)} feature={constants.FEATURES.ADMIN} />
                    )}
                  />
                  <Route component={NotFound} />
                </Switch>
              </div>
            </div>
          )}
          {isLoggedIn && user.isOneTimePassword && (
            <div>
              <Switch>
                <Route exact path="/" component={NewPassword} />
              </Switch>
            </div>
          )}
          {!isLoggedIn && (
            <div>
              {this.purgeStore()}
              <Switch>
                <Route exact path="/" component={Login} />
                <Route path={constants.ROUTES.RESET_PASSWORD} component={ResetPassword} />
                <Route path={constants.ROUTES.NEW_PASSWORD} component={NewPassword} />
                <Route component={NotFound} />
              </Switch>
            </div>
          )}
        </div>
      </Router>
    );
  }
}

LoginCheck.propTypes = {
  accessToken: PropTypes.string,
  featurePermissions: PropTypes.object,
  user: PropTypes.object,
  selectedOrganizationId: PropTypes.number,
};

const mapStateToProps = (state) => {
  const {
    accessToken,
    featurePermissions,
    user,
    selectedOrganization: { id: selectedOrganizationId },
  } = state.login;

  return {
    accessToken,
    featurePermissions,
    user,
    selectedOrganizationId,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    loginSuccess: (featurePermissions, user, userRole) =>
      dispatch(LoginActions.loginSuccess(featurePermissions, user, userRole)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LoginCheck);
